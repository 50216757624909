:root {
    --primary-color: #0984e3;/* Fioletowy */
    --secondary-color: #A7519E; /* Różowy */
    --background-color: #0984e3; /* Niebieski */
    --text-color: #2d3436;
  }
  

  
  .login-container {
    background-color: white;
    padding: 50px;
    border-radius: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px;
    position: relative;
    z-index: -1;
  }

  
  
  .forgot-password {
    text-align: center;
    margin-top: 20px;
  }
  
  .forgot-password a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .forgot-password a:hover {
    color: var(--secondary-color);
  }
  

  .login-main{
    display: flex;
    justify-content: center;
    align-items: center;
  }
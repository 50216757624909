:root {
  --primary-color: #0056b3;
  --secondary-color: #A7519E;
  --background-color: #f4f4f4;
  --text-color: #333;
  --border-color: #e0e0e0;
  --hover-color: #f0f0f0;
}
/* Wybór całego paska przewijania */
::-webkit-scrollbar {
  width: 2px; /* Szerokość pionowego paska przewijania */
  height: 2px; /* Wysokość poziomego paska przewijania */
}

/* Wybór tła paska przewijania */
::-webkit-scrollbar-track {
  background: #A7519E; /* Kolor tła paska przewijania */
  border-radius: 10px; /* Zaokrąglenie krawędzi paska przewijania */
}

/* Wybór suwaka (thumb) */
::-webkit-scrollbar-thumb {
  background: #A7519E; /* Kolor suwaka */
  border-radius: 10px; /* Zaokrąglenie krawędzi suwaka */
}

/* Zmiana koloru suwaka po najechaniu myszą */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Kolor suwaka po najechaniu */
}


body {
 
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100vw !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;

}

.main{
  overflow-y: scroll;
  overflow-x: hidden!important;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 10vh;

}


 h1, h2, h3, a {
  font-family: "Montserrat", sans-serif !important;
  text-transform: uppercase;
  text-decoration: none !important;
  color: #0056b3 !important;
  font-weight: 400 !important;
}
h6 { 
  font-family: "Montserrat", sans-serif !important;
}
p{
  font-family: 'Lato', sans-serif;
}
h1 {


  color: var(--primary-color);


  font-size: 7rem !important;
  text-align: start !important;

}
.work-ad-detail h1{
  font-size: 3em !important;

}

.footer {
  width: 100vw !important;
}
.blue {
  color: #1A5D96 !important;
}
.purple {
  color: #A7519E !important;
}
.infoteka-color{
  color: #ffca2c !important;
}
.infoteka-bg {
  background-color: #ffca2c;
}
.green { 
  color: #28a745 !important; 
}
.cyan { 
  color: #17a2b8 !important; 
}
.plum { 
  color: #6f42c1 !important; 
}
.indigo { 
  color: #6610f2 !important;
}

.orange { 
  color: #fd7e14 !important;


}
.orange_bg{
  background-color: #fd7e14;
}

.teal{
  color: #20c997 !important;
}
.pink{
  color: #d63384 !important;
}

.logo-h1{ 
  font-family: "Montserrat", sans-serif;
font-style: normal;
font-weight: 700;

line-height: 182px;

color: #A7519E;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
h3, h2{
  font-family: "Montserrat", sans-serif;

  color: #1A5D96;
}
.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.section {
  padding-top: 5vh;
}
/* gradients */
.sky{
  background: #E0EAFC;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #CFDEF3, #E0EAFC);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #CFDEF3, #E0EAFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.shore{
  background: #70e1f5;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #ffd194, #70e1f5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.moonrise{
  background: #DAE2F8;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #D6A4A4, #DAE2F8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.delicate{
  background: #D3CCE3;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.cloude { 
  background: #ECE9E6;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFFFFF, rgba(248, 241, 245, 0.5));  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF,rgba(248, 241, 245, 0.5)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}




form { 
 
  padding: 10%;
}

.App-logo{
  width: 30px;
}

section{
  min-height: 100vh;
}


.container, .row { 
  position: relative !important;
}

#obrotni .col-lg-5 {
  position: absolute;
  right: 10%;
  z-index: 0;
}
/* Wspólnie! */

.newsletter-form  { 
  display: flex;
  align-items: center;
 
}
.logo { 
  width: 45px;
}

.newsletter .btn-outline-dark{
  border: #1A5D96 1px solid;
  color: #1A5D96;
}
.newsletter input {
  border: #A7519E solid 1px;
  padding: 2%;
  color: #A7519E;
  font-weight: 700;
}

.newsletter label{
  color: #A7519E;
}

@keyframes rotate-infinity {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-object {
  animation: rotate-infinity 2s linear infinite;
}



@media  screen and (max-width:800px) {
  label{
    font-size: 9px;
  }
  form .btn{
    margin-top: 5%;
  }
  .col-12, .col-sm-12 {
    margin-block: 2%;
  }
  .title { 
    font-size: 3rem;
  }
}





.message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: #1A5D96;
}

.message-box {
  background-color: white;
  padding: 5%;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.message-box p {
  margin: 0 0 20px 0;

}
.message-box .btn-outline-dark {
  color: #1A5D96;
  border: solid #1A5D96;
}
.message-box .btn-outline-dark:hover {
  color: #A7519E;
  background-color: aliceblue;
  border: solid #A7519E;
}
.btn-outline-dark { 
  height: 100%;
}
.btn-outline-dark:hover{
  background-color: #A7519E !important;
  color: white;
  border:none !important;
}
.footer-logo{
  width: 50px;
}

.footer{
  width: 100vw;
  background-color: white;
  padding: 5%;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
color: #1A5D96;

}
a { 
  text-decoration: none;
  color: #A7519E;
  font-weight: 600;
}

#loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
}
#loading img { 
  width: 50%;
  height: 70%;
}

/* infoteka */
.photo-press {
  height: 70vh;
  width: auto;
}





.neumorphism{
  
background: #fff;
box-shadow:  2px 2px 6px #e8e8e8,
             -2px -2px 6px #ffffff;
}
.gradient-text {
  background: -webkit-linear-gradient(#0056b3, #A7519E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  
}
.vh10{
  margin-block: 10vh !important ;
}


/* navbar */





.btn.animate {
  animation: textFadeInOut 1.25s ease-in-out;
}



.ontop{
  z-index: 9999999999999;
}


/* sekcje */


@media screen and (max-width:1000px) {
  #intro img{
    width: 100% !important;
  }
  #intro .rotating-object { 
    width: 50% !important;
  }
  #newsletter { 
    display: flex;
    align-items: center;
  }
  #basic-navbar-nav,
  #infoteka-navbar-nav { 
    z-index: 9999;
    width: 100% !important;
    margin: auto 0 !important;
    padding-left: 5%;
 
    background-color: #fff !important;
  }
}

.card-obrotni { 
  height: 300px !important;
  font-weight: 300;
  overflow: hidden;
}
.card-obrotni img {
object-fit: cover !important;

}

.card-info { 
  height: 45vh !important;
  font-weight: 300;
  overflow: hidden;
}
/* infotekaa */

.infoteka-all .scroll-container .card {
  overflow-y: scroll;
  height: 40vh;

}

.card-button {
  position:relative;
  bottom: 0;
  text-decoration: none;
  color: #000;

  width: 100%;
  left:0;
}
.card-category {
  position: absolute;
  background-color: #ffca2c;
  top:5px;

  width:100%;
  text-align: center;
  padding: 2%;
  font-weight: 300 !important;
}
.card-category-work {
  position: absolute;
  background-color: #fd7e14;
  font-size: 12px;

  top:5px;

  width:100%;
  text-align: center;
  padding: 2%;
}


.card-category-obrotni {
  position: absolute;
  background-color: #0c0c0b;
  font-size: 12px;

 

  top:5px;
  color: #ffff;
  width:100%;
  text-align: center;
  padding: 2%;
}



.card{
  word-wrap: break-word!important;
}

.d-none {
  display: none !important;
}


.menu-wrapper {

 min-height: 75px !important;

  width: 110%;
 display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  margin-inline: 0;
  padding-inline: 0;
  align-items: center !important;

}
.menu-wrapper .menu-item {
  height: 100%;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0px;
  overflow: auto;
  white-space: nowrap;
  overflow-y: hidden;
}
.menu-wrapper .menu-item a {
  display: inline-block;

  color: #333;
  height: 100%;
}
.menu-wrapper .menu-item a:hover {
  text-decoration: none;
}

.menu-wrapper .menu-item::-webkit-scrollbar {

 color: #ffca2c;
}
.menu-wrapper .pointer {
  height: 50px;
  width: 30px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  margin-top:10px;

}

.menu-wrapper .left-pointer {
  left: 0px;
  top:10px;
}
.menu-wrapper .right-pointer {
  right: 0px;
  padding-right: 10px;

}
.menu-wrapper .dis i {
  color: #ccc;
}

a.active{
  color: red;
}
.menu-wrapper .menu-item::-webkit-scrollbar {
  width: 10px; /* Możesz dostosować szerokość paska przewijania */
}

.menu-wrapper .menu-item::-webkit-scrollbar-track {
  background: #f1f1f1; /* Kolor tła ścieżki paska przewijania */
}

.menu-wrapper .menu-item::-webkit-scrollbar-thumb {
  background: #ffca2c; /* Kolor "kciuka" paska przewijania */
}

.menu-wrapper .menu-item::-webkit-scrollbar-thumb:hover {
  background: #e0b422; /* Kolor "kciuka" przy najechaniu */
}







.loader { 
  width: 50%;
  max-width: 500px;
}

.info-message { 
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  z-index: 999999999999;
}

.info-message-text{
  background-color: #fff;
  width: 75%;
  max-width: 800px;
  height: 50vh;
  max-height: 500px;
  margin-left: 25%;
  display: flex;
  align-items: center;
  z-index: 999999;
  padding: 5%;

  
}






.work button {
  background-color: #fd7e14 !important;
  border: 0px !important;
  color: #ffff !important;
}
.orange-button {
  background-color: #fd7e14;
  border: none;
  color: #ffff;

}
.orange-button:hover{
  background-color: #eb954e !important;
  font-size: larger;
  color: #fff;
}
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in.visible {
  opacity: 1;
}

.border-bottom-orange {
  border-bottom: solid #fd7e14 1px;
}


.glass {
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}
.glass2 {
  /* From https://css.glass */
background: rgba(65, 214, 83, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}


.profile {
  position: relative;
  min-height: calc(100vh - 80px); /* assuming footer height is 80px */
  padding-bottom: 80px; /* same as footer height */
  padding-top: 100vh;
}

.profile-img-container {
  width: 100vw;
  height: 80vh;
  padding-left: 10%;
  position: fixed;
  top: 10vh;

}

.profile-img-container .profile-img {
  height: 80vh;
  width: 50vw;
  max-width: 1200px;
  position: fixed;

  z-index: -10;
}

.profile-img-container h2 {
  font-size: 8rem !important;
}

.profile-img { 
  height: 60vh;
  position: relative !important;
}
.profile-img-container h2{
  font-size: 8rem !important;

}

.profile-img { 
  height: 60vh;
  position: relative !important;
}

.profile-overlay{
  position: absolute;
  z-index: 9999;
  background-color: rgba(0, 86, 179,0.8);
  width: 100%;
  height: 100%; 
  color: #fff;
  display: none;
  transition: display 0.3s ease-in-out;
  top:0;
}
.profile-img:hover .profile-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.profile-overlay h2{
  color: #fff !important;
}
.profile-img img{
  object-fit: cover;
  width: 100%;
  position: inherit;
  z-index: 2;
  
}

.profile-name {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-inline: auto;
  margin-top: 5%;
  position: relative;

}
.profile-name h2{
  position: relative;
  font-size: 5rem;
  z-index: 1;
  
}
.bi-pencil-square{
  font-size: 50px;
  color: #0056b3;
}
.profile-name h3{
  position: absolute;
  font-size: 4rem;
  z-index: 0;
  transform: scale3d(3, 4, 5) translateX(35%) translateY(25%);
  font-weight: 900;

  color: #FFFFFF;
  background: transparent;
  text-shadow: 0.5px 0.5px 0 rgba(196, 195, 196, 0.3), 0.5px -0.5px 0 rgba(196, 195, 196, 0.3), -0.5px 0.5px 0 rgba(196, 195, 196, 0.3), -0.5px -0.5px 0 rgba(196, 195, 196, 0.3), 0.5px 0px 0 rgba(196, 195, 196, 0.3), 0px 0.5px 0 rgba(196, 195, 196, 0.3), -0.5px 0px 0 rgba(196, 195, 196, 0.3), 0px -0.5px 0 rgba(196, 195, 196, 0.3);
  color: #FFFFFF;
  opacity: 0.2;
  text-transform: capitalize;
}
.profile-name h4{
  position: absolute;
  font-size: 4rem;
  z-index: 0;
  transform: scale3d(3, 2, 4) translateX(35%) translateY(155%);
  font-weight: 900;

  color: #FFFFFF;
  background: transparent;
  text-shadow: 0.5px 0.5px 0 rgba(196, 195, 196, 0.3), 0.5px -0.5px 0 rgba(196, 195, 196, 0.3), -0.5px 0.5px 0 rgba(196, 195, 196, 0.3), -0.5px -0.5px 0 rgba(196, 195, 196, 0.3), 0.5px 0px 0 rgba(196, 195, 196, 0.3), 0px 0.5px 0 rgba(196, 195, 196, 0.3), -0.5px 0px 0 rgba(196, 195, 196, 0.3), 0px -0.5px 0 rgba(196, 195, 196, 0.3);
  color: #FFFFFF;
  opacity: 0.2;
  text-transform: capitalize;
}

.profile-name h5{
  position: absolute;
  font-size: 5rem;
  z-index: 0;
  transform: scale3d(3, 2, 4) translateX(35%) translateY(210%);
  font-weight: 900;

  color: #FFFFFF;
  background: transparent;
  text-shadow: 0.5px 0.5px 0 rgba(176, 86, 176, 0.3), 0.5px -0.5px 0 rgba(174, 51, 174, 0.3), -0.5px 0.5px 0 rgba(162, 12, 162, 0.3), -0.5px -0.5px 0 rgba(81, 8, 81, 0.3), 0.5px 0px 0 rgba(152, 55, 152, 0.3), 0px 0.5px 0 rgba(130, 54, 130, 0.3), -0.5px 0px 0 rgba(196, 195, 196, 0.3), 0px -0.5px 0 rgba(217, 56, 217, 0.3);
  color: #FFFFFF;
  opacity: 0.2;
  text-transform: capitalize;
}
.profile .col-md-3  {

min-height: 500px;
max-height: 600px;
height: 50vh;
overflow: hidden;
border-radius: 20px;
transition: transform 0.3s ease-in-out;
}
.profile .col-md-3:hover  {
 transform: scale(1.2);
 max-width: 100vw;
 position: relative;
z-index: 999999;
  }
.profile .col-md-3 img {
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.profile .col-md-3 img:hover {
  
  object-fit: cover;


}

.profile .row {
  margin-block: 15vh;
}


.profile-update {
  margin-bottom: 30vh !important;
 
}
.profile-gallery-img{
  max-width: 100%;
}

.profile-gallery-overlay{
  position: absolute;
  z-index: 9999;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  max-width: 400px;
  height: 100%; 
  color: #fff;
  display: none;
  transition: display 0.3s ease-in-out;
}
.profile-gallery-overlay h2{
  color: #fff !important;
}
.profile-gallery-img:hover .profile-gallery-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}















h2 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
}

p {
  font-weight: 300;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
}



.leben-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.leben-overlay:hover {
  opacity: 1;
}


.cv-dis {
  transition: opacity 0.5s ease-out;
}

.cv-dis.hide {
  opacity: 0;
}


.create-add {
  min-height: 80vh;
}
.create-add .col-md-6{

  border: #0056b3 1px solid;
  max-width: 500px ;
  height: 40vh;
  min-height: 200px;
  border-radius: 25px;
  color: #ffff;
}

.create-add .col-md-6 p{
color: #0056b3;
}





.gallery {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  gap: 20px;
  padding-top: 10vh;
  margin-top: 30px;
  min-height: 120vh;
}
.gallery-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.gallery-item img {
  width: auto;
  max-height: 50vh;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.gallery-item:hover img {
  transform: scale(1.05);
}

.gallery-item:hover .gallery-caption {
  transform: translateY(0);
}
.profi-description { 
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 20px;
}












/* background */

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  z-index: -100;
}

.shape {
  position: absolute;
  opacity: 0.7;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  top:  100px;
  left: -40px;
}

.square {
  width: 200px;
  height: 200px;
  background-color: var(--primary-color);
  bottom: -100px;
  right: -100px;
  transform: rotate(45deg);
}

.triangle {
  width: 0;
  height: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-bottom: 250px solid var(--background-color);
  top: 30%;
  right:-20%;
  transform: rotate(20deg);
}
@media screen and (max-width: 768px) {
.triangle {
display: none!important;
}
}



.menu-profile{
    top: 0;
  height: 5vh !important;
  display: flex;
  align-items: center;
  width: 300px;
  position: absolute;
  right: 0;
  top: 10vh;
  z-index: 1000000000000;
  

}

.animated-button{
  width: 250px;
  height: 50px;
  margin: 1% !important;
  font-size: 13px;
  text-transform:uppercase;
  font-weight: 600;
  border: #0056b3 solid 1px !important;
  color: #0056b3;
}
.check-icon {
  position: absolute;
}







@media (max-width: 700px) {


  .big-text{
    position: absolute;

    top:-10%;
    font-size: 15vw !important ;
    
}
.big-text-obrotni{
  position: absolute;
  left:10% !important;
  top:15%;
  font-size: 12vw !important ;
  
}
.big-text-vol2{
  position: absolute;
  margin-top: -30vh !important;
  font-family: 'Lato';
  opacity: 0.8;
  z-index: -1;
  color: #A7519E;
  font-size: 4rem !important;
  text-transform: uppercase;
  text-shadow: -1px 1px 0 #000,
      1px 1px 0 #000,
      1px -1px 0 #000,
      -1px -1px 0 #000;
}
.job-menu{
 margin-top: 35vh !important;
background-color: rgba(0, 0, 0, 0.25); 

padding-block: 0!important;

}
.drop-small{
  margin-top: 15px!important;
}
.work-row{
  margin-top: 15vh!important;
}
}



.big-text{
  position: absolute;
  margin-top: -15%;
  margin-left: 10%;
  opacity: 0.6;
  z-index: -1;
  color: #A7519E;
  font-size: 16rem;
  text-shadow: -1px 1px 0 #000,
      1px 1px 0 #000,
      1px -1px 0 #000,
      -1px -1px 0 #000;
}


.big-text-obrotni{
  position: absolute;
  margin-top: 0%;
  margin-left: 0%;
  opacity: 1;
  z-index: -1;
  color: #A7519E;
  font-size: 16rem;

}



.big-text-vol2{
  position: absolute;
  margin-top: -30%;
  font-family: 'Lato';
  opacity: 0.8;
  z-index: -1;
  color: #A7519E;
  font-size: 10rem;
  text-transform: uppercase;
  text-shadow: -1px 1px 0 #000,
      1px 1px 0 #000,
      1px -1px 0 #000,
      -1px -1px 0 #000;
}

.banner-row{
  height: 50vh;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}
.banner-row img{
  object-fit: cover;
  height: 100%;
}
@media screen and (max-width: 700px) {
  h1 {
    font-size: 32px !important; /* Zmniejszenie rozmiaru fontu dla lepszego dopasowania */
  }
  .work-ad-detail h1{
    font-size: 2em !important;
  }
  .work-search {
    width: 100vw !important;
    display: flex;
    align-items: center !important;
    flex-direction: column; /* Ustawienie kolumny zamiast wiersza */
    margin-top: 20px;
  }

  .work-search form {
    width: 100% !important; /* Ustawienie szerokości formularza na 100% */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .work-search form input {
    width: 80% !important; /* Ustawienie szerokości inputu */
    margin-bottom: 10px;
  }

  .work-search form button {
    width: 80% !important; /* Ustawienie szerokości przycisku */
  }

  .job-list {
    transform: translateY(70px);
  }

  .navbar .container {
    flex-direction: column; /* Ustawienie kolumny dla kontenera nawigacji */
  }

  .navbar .container .row {
    width: 100%; /* Ustawienie szerokości na 100% */
    margin: 0;
  }

  .navbar .container .col-md-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px; /* Dodanie odstępu między kolumnami */
  }

  .menu-work {
    height: auto;
  }

  .work-row {
    min-height: 100vh;
  }

  .neumorphic-button {
    width: 80% !important; /* Ustawienie szerokości przycisków */
  }
}

.navbar-extra {


  box-shadow: 20px 20px 60px #bec3c9, -20px -20px 60px #ffffff;
  display: flex;
  align-items: center !important;
}

.neumorphic-button {
  background-color: #e0e5ec;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 10px;
  color: #4a4a4a;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 5px 5px 10px #bec3c9, -5px -5px 10px #ffffff;
}

.neumorphic-button:hover,
.neumorphic-button:focus {
  box-shadow: inset 5px 5px 10px #bec3c9, inset -5px -5px 10px #ffffff;
}

.neumorphic-input {
  background-color: #e0e5ec;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: inset 5px 5px 10px #bec3c9, inset -5px -5px 10px #ffffff;
}

.neumorphic-input:focus {
  outline: none;
  box-shadow: inset 7px 7px 14px #bec3c9, inset -7px -7px 14px #ffffff;
}


.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.form-container {
  clear: both;
  margin-top: 20px; /* Opcjonalne: dodanie marginesu górnego */
}

.work-ad-detail{
 margin-top:10vh;

}

.work-all {
  min-height: 55vh;

  overflow-x: auto;
  white-space: nowrap;
  padding: 0px;

  display: flex;
  align-items: center;
}

.work-all .card {
  display: inline-block;
  width: 300px;
  margin-right: 10px;
}

/* Custom scrollbar styles */
.work-all::-webkit-scrollbar {
  height: 12px; /* Scrollbar height */
}

.work-all::-webkit-scrollbar-track {
  background-color: #e0e5ec;
  border-radius: 10px; /* Scrollbar track rounding */
}

.work-all::-webkit-scrollbar-thumb {
  background: rgba(253, 126, 20, 1) !important; /* Scrollbar thumb color */
  border-radius: 10px; /* Scrollbar thumb rounding */
  box-shadow: 5px 5px 10px #fd7e14, -5px -5px 10px #ffffff; /* Scrollbar thumb shadow */
}

.work-all::-webkit-scrollbar-thumb:hover {
  background: #fd7e14 !important; /* Scrollbar thumb hover color */
  box-shadow: 5px 5px 10px #fd7e14, -5px -5px 10px #ffffff; /* Scrollbar thumb hover shadow */
}








.infoteka-all {
  min-height: 55vh;

  overflow-x: auto;
  white-space: nowrap;
  padding: 0px;

  display: flex;
  align-items: center;
}

.infoteka-all .card {
  display: inline-block;
  width: 300px;
  margin-right: 10px;
  white-space: wrap !important;
}
.card-text, .card-title {
  white-space: wrap;
}

/* Custom scrollbar styles */
.infoteka-all::-webkit-scrollbar {
  height: 12px; /* Scrollbar height */
}

.infoteka-all::-webkit-scrollbar-track {
  background-color: #e0e5ec;
  border-radius: 10px; /* Scrollbar track rounding */
}

.infoteka-all::-webkit-scrollbar-thumb {
  background: #ffca2c !important; /* Scrollbar thumb color */
  border-radius: 10px; /* Scrollbar thumb rounding */
  box-shadow: 5px 5px 10px #ffca2c, -5px -5px 10px #ffffff; /* Scrollbar thumb shadow */
}

.infoteka-all::-webkit-scrollbar-thumb:hover {
  background: #ffca2c !important; /* Scrollbar thumb hover color */
  box-shadow: 5px 5px 10px #ffca2c, -5px -5px 10px #ffffff; /* Scrollbar thumb hover shadow */
}












.vh30 {
  margin-top: 30vh;
}


.vh15 {
  margin-top: 15vh;
}

.card-image { 
  width:300px;
  height: 300px;
  overflow: hidden !important;
}



.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
}
.profile-picture {
  width: 100%;
  object-fit: cover;
  border: 8px solid white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.profile-details {
  font-size: 1.4rem;
  max-width: 600px;
  margin: 0 auto;
  font-weight: 800;
}
.profile-details p {
  margin: 10px 0;
  font-weight: 400;
}
.section-title {
  font-family: 'Lato', serif;
  font-size: 2.5rem;
  color: var(--secondary-color);
  margin: 60px 0 30px 0;
  text-align: center;
}
.about-me {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 60px;
}
.gallery-item {
  position: relative;
  overflow: hidden;
}
.gallery-item:hover {
  transform: translateY(-10px);
}
.gallery-item img {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.gallery-item:hover .gallery-caption {
  transform: translateY(0);
}

.add-persone:hover{
  transform: scale(1.1);
  cursor: pointer;
}


.add-persone{
  border-radius: 20px;


}









.obrotni-all {
  min-height: 55vh;
  margin-top: 0vh;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0px;

  display: flex;
  align-items: center;
}

.obrotni-all .card {
  display: inline-block;
  width: 300px;
  margin-right: 10px;
}

/* Custom scrollbar styles */
.obrotni-all::-webkit-scrollbar {
  height: 12px; /* Scrollbar height */
}

.obrotni-all::-webkit-scrollbar-track {
  background-color: #e0e5ec;
  border-radius: 10px; /* Scrollbar track rounding */
}

.obrotni-all::-webkit-scrollbar-thumb {
  background: rgb(20, 20, 20) !important; /* Scrollbar thumb color */
  border-radius: 10px; /* Scrollbar thumb rounding */
  box-shadow: 5px 5px 10px #090909, -5px -5px 10px #ffffff; /* Scrollbar thumb shadow */
}

.obrotni-all::-webkit-scrollbar-thumb:hover {
  background: #050505 !important; /* Scrollbar thumb hover color */
  box-shadow: 5px 5px 10px #0f0f0f, -5px -5px 10px #ffffff; /* Scrollbar thumb hover shadow */
}



.nowi{
  margin-top:45vh;
}




.landy {
  width: 100vw;
  min-height: 100vh;
  margin-top: 10vh;
  margin-bottom: 10vh;  
}

.landy ul {

  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align the list items to the end */
}

.landy li {
  cursor: pointer;
  border-bottom: #0056b3 .5px solid;
  font-size: 15px;
}

.landy li:hover {
  cursor: pointer;
  border-bottom: #0056b3 .5px solid;
  color: #0056b3;
  font-size: 15px;
  transform: scaleX(1.5) translateX(-20px);
}
.landy-col { 
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.miasta {
  margin-top: 20vh;
}


.forum { 
  margin-block: 30vh;
}

.landy .card {
  overflow: hidden;
}


.thread-list {
  flex: 0 0 300px;
  background-color: white;
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
}

.thread-item {
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100% !important;
  
}

.thread-item:hover, .thread-item.active {
  background-color: var(--hover-color);
}


.thread-item p {
  margin: 0;
  font-size: 14px;
  color: #666;
}
/* Default when no thread is selected */
.discussion-window.no-thread-selected {
  height: auto;
  overflow-y: auto;
}

/* When a thread is selected */
.discussion-window.thread-selected {
  height: auto;
  min-height:70vh;
  max-height:120vh;
  overflow-y: hidden;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/* Mobile-specific adjustments */
@media screen and (max-width: 768px) {
  .this-acc {
    padding-left: -10%!important;
    margin-left: -5%!important;
  }
}


.discussion-content {
  height: auto;
  min-height: 30vh; /* Ustaw odpowiednią wysokość */
  overflow: hidden;
  
}

.discussion-scroll {
  height: calc(100% - 100px); 
  overflow-y: scroll; 
}
.discussion-header {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
  padding-bottom: 50vh;
}
.reply-form {

  position: absolute;
  flex-shrink: 0; 
  bottom: 0;
  z-index: 5;
}
.reply-form textarea {


  width: calc(100% - 40px) !important;

  z-index: 999990;
}
.discussion-header h2 {
  margin: 0;
  color: var(--primary-color);
}
.regulamin {
margin-top: 5vh !important;
}
.post {

  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
border-radius: 16px;

  margin-block: 25px;
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.post-author {
  font-weight: bold;
  color: var(--secondary-color);
 
  display: flex;
  width: 300px;
  align-items: center;
  margin-block: 25px;
}
.post-author img {
  width: 80px;
}

.post-date {
  font-size: 12px;
  color: #666;
}

.post-content {
  font-size: 14px;

}
.reply-form {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 2%;
  padding-bottom:2%;
  width: 100%;


}
.lets-reply {
width:95%;
display: flex;
align-items: center;
justify-content: between !important;
background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.reply-form textarea {
  width: 90% !important;

  height:10vh;
  padding: 2%;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-right: 10px;
  padding-top:1%!important;


}

.reply-form button {
  margin-top: 10px;
  background-color: var(--secondary-color);
  color: white !important;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reply-form button:hover {
  background-color: #8a4084;
}

.forum-description {
display:flex;
align-items: center;
width: 100%;
height:100%;
padding:5%;
}

.bi-arrow-up-right-circle{
  font-size: 30px;
}
.d-flex-small {
display: block !important;
}
.comment {
  display: flex;
  align-items: center;
  margin-block: 25px;
  border-bottom: rgba(26, 93, 150, 0.2) solid 1px;
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
.miasto-link {
font-size: 15px !important;
}
.comment {
  display: block!important;
  align-items: center;
  margin-block: 25px;
  border-bottom: rgba(26, 93, 150, 0.2) solid 1px;
  padding-bottom: 10px;
}
.melden{
font-size: 10px !important;
}
.post {
margin-inline : 0 !important;
padding-inline : 0 !important;
width:100vw !important;
}
.post-header {
padding-inline : 10% !important;
width:100vw !important;
}
textarea {
width:99vw !important;
width:99vw !important;
}
.small-comment {
display: flex;
justify-content: flex-end;
align-item:center;
}
.comment p {
  margin-left: 0px;
}
}
.comment p {
  margin-left: 20px;
}

.comment-img{
  width: 50px;
}
.ki {
  background-color: rgba(0, 0, 0, 0) !important;
  width:150px !important;
  border: none;
  color: #8a4084 !important;
  font-size: 15px;
}
.ki:hover{
  background-color: rgba(0, 0, 0, 0.1);
}
.ki i.bi-translate {
  opacity: 0; /* Ukryj ikonę */
  transition: opacity 0.3s ease; /* Płynna zmiana widoczności */
}

.ki:hover i.bi-translate {
  opacity: 1; /* Pokaż ikonę przy najechaniu */
}
.category-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 90%;
}

.forum p {
font-size:1.1rem;
}





.job-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding-block: 10vh;
}

.category {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}
.category h3:hover, .category h4:hover{
color: #fff;
}

.category:hover {
  background-color: #3498db;
  color: white !important;
  transform: translateY(-5px);
}

.category svg {
  width: 50px;
  height: 50px;
  margin-bottom: 0.5rem;
}

.category h3 {
  margin: 0;
  font-size: 1.1rem;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}



.contact-list{
  max-height: 100vh;
  overflow-y: scroll ;
}
.chat {

  margin-bottom:10vh;
}
.chat-window{
  max-height: 70vh;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
  margin-top: 23vh !important;
  padding: 2%;
}
/* giv me three classes margin-20vh and ten more etc */
.private-chat-form {
  position: absolute;
  width: 103%;
  padding-right: 0;
  margin-right: 0;
  z-index: 9;
 
  height: 80px;
 display: flex;
 align-items: center;
}
.chat-menu {
display:block !important;
position: fixed;
z-index: 9;
right:5%;
top:150px;
transition: opacity 0.3s ease-in-out;
}
.chat-menu.hidden {
  opacity: 0;
  pointer-events: none;
}

.chat-menu.visible {
  opacity: 1;
}
 .private-chat-form form {
  position: absolute;



}
.private-chat-form form button {
 height: 100%;

}

.private-chat-form textarea {
  height: 50px;
}







.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.message-content {
  padding: 10px;
  border-radius: 10px;
}


.konwersacje {
  overflow-y: scroll;
}
.konwersacje button {
  font-family: "Montserrat", sans-serif !important;
}

.btn-primary {
  border-bottom: #A7519E !important;
  color: #A7519E !important;
  background-color: #ebebeb !important;
  border: none!important;
}
.offcanvas {
  z-index: 999999999999999999;
}

.card {
  overflow: hidden !important;
}

.vh15 { 
  margin-top: 10vh;
}


.btn{
  font-family: "Montserrat", sans-serif !important;
}
.notification-drop{
  width: 50vw;
  min-width: 300px;
  font-size: 15px;
  text-transform: lowercase;
}
.cv-img {
width: 70px;
border-radius: 50% 50%;
}
.cv-record{
max-width: 300px;

}
.cv-record a {
font-size: 20px;
}


.new-message {
  background-color: green;  /* Zmienia tło na zielone */
  color: white;             /* Zmienia tekst na biały */
}
.job-item {
  pointer-events: auto;
  z-index: 1;
}


.instruction{
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.8);
width:100vw;

height:100vh;
z-index: 99999999999999999;
display: flex;
justify-content: center;
align-items: center;
}
.close-instruction {
height: 40px;
}
.px-30 {
padding-inline: 20%;
}

.gear {
    position: relative;
    font-size:25px;
}

.hover-text {
    display: none;
    position: absolute;
    top: 100%; /* Position the text below the gear icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 9999;
}

.gear:hover .hover-text {
    display: block;
}
.chat-icon {
    position: relative;
    font-size: 25px;
}

.hover-text-mess {
    display: none;
    position: absolute;
    top: 100%; /* Position the text below the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 9999;
}

.chat-icon:hover .hover-text-mess {
    display: block;
}
.gridy {
opacity: 0.1;
position: fixed;
z-index:-9999;
top:0;
}



.button-container {
  position: relative; /* Ustawienie dla pozycjonowania absolutnego */
}

.hover-message {
  display: none; /* Domyślnie ukryty */
  position: absolute;
  top: 100%; /* Pozycjonowanie poniżej przycisku */
  left: 0;
  background-color: #f8f9fa; /* Jasne tło */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  white-space: nowrap;
}

.button-container:hover .hover-message {
  display: block; /* Pokaż, gdy najechano na .button-container */
  left:0;
}
.bi-exclamation-lg, .bi-file-arrow-down-fill {
font-size:25px !important;

}

.work-container-main {
margin-top: 10vh !important;
}


@media (pointer: coarse) {
    .spin-container {
        animation: spin 20s infinite linear !important;
    }
}

.container.miasta {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.container.miasta h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #1a73e8;
    margin-bottom: 20px;
    letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
.miasta {
margin-top: 10vh !important;
}
}
.list-inline {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    padding: 0;
    list-style-type: none;
}

.list-inline-item {
    margin-bottom: 10px;
}

.miasto-link {
    font-size: 1.1rem;
    color: #0d47a1;
    text-decoration: none;
    transition: color 0.3s ease;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #e3f2fd;
}

.miasto-link:hover {
    color: #1a73e8;
    background-color: #bbdefb;
    text-decoration: underline;
}

.miasta h3 {
    font-weight: 500;
    margin: 0;
}


.img-info {
width: 100%;
}
.card-body-main {
background-color: rgba(0,0,0,0.7);
width: 100% !important;
margin-block : 10%;
padding-block:2%;
}

.highlighted {
  border: 2px solid green !important;
  background-color: #e6ffe6 !important;
}
.info-video {
 position: relative;
width:100vw !important;
  padding-top: 56.25%; /* Współczynnik proporcji 16:9 */
  overflow: hidden;

}
.info-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: none;
}

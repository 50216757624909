.wrap50{ 
    margin-top: 10vh;

}

h1{
    font-size: 10vw;
}
img { 
    width: 50%;
}
.spiral {
    position: relative;
    width: 100%;
    flex: 1;
    justify-content: center;
}
.spiral img:nth-child(1){
    margin-right: 25%;
}
.spiral img:nth-child(1),
.spiral img:nth-child(3) { 
    width: 20%;
    margin-top: 20%;
    position: relative;
    z-index: 999;
}
.obrotni { 
    position: absolute;
    width: 50%;
    z-index: 0;
    left: 30%;
    top: 0;
}